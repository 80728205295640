.add-tender-container {
	margin-top: 2rem !important;
	padding-right: 2rem;
	text-align: right;
}

.tenders-container {
	.card {
		border-radius: 0;
		background-color: #FFF;
	}
	.table-container {
		padding-top: 0;
		padding-bottom: 0;
	}
}

#dashboard-tenders-table {
	padding-bottom: 0 !important;
}

#dashboard-tenders-table .card {
	border: none;
}

#page-client-dashboard {
	.open-tenders {
		.btn {
			font-size: 1.7rem;
		}
		.dropdown-menu {
			font-size: 1.5rem;
		}
	}
	@include media('>480px') {
		.table-responsive {
			overflow-x: visible;
		}
	}
}
