#page-helicopter {
  .page-title-container {
    padding-bottom: 2rem;
    border-bottom: none;
  }
  table.table td a {
    display: inline-block;
  }
  #helicopter_map {
    height: 400px;
    margin-bottom: 3rem;
    z-index: 1;
  }
  .helicopter-detail {
    width: 200px;
    .photo {
      width: 100%;
    }
    .spec {
      background: rgb(38, 69, 139);
      color: #FFF;
      margin-top: 1rem;
      padding: 1rem;
      div span {
        &:first-child {
          padding-right: 1rem;
          font-weight: bold;
        }
      }
    }
  }
  .table-header .btn {
    font-size: 1.2rem !important;
    margin: -0.2em 1em 1em 0;
  }
  .destination-input {
    margin-left: 10px;
    max-width: 100px;
  }
  .passengers-input {
    max-width: 100px;
  }
  .heli-type-input {
    margin-left: 10px;
    margin-right: 15px;
    max-width: 300px;
  }
}