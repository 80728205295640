// -------------------------------------------------------
// Main files
// -------------------------------------------------------

$breakpoints: (
    'xs-phone': 320px,
    'phone': 480px,
    'tablet': 768px,
    'desktop': 1024px,
    'widescreen': 1200px,
    'xl-widescreen': 1500px
);

// keywords
$media-expressions: (
    'screen': 'screen',
    'print': 'print',
    'handheld': 'handheld',
    'landscape': '(orientation: landscape)',
    'portrait': '(orientation: portrait)',
    'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
    'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

@import 'vendors/bootstrap.min';
@import 'vendors/mdb';
@import 'vendors/font-awesome/scss/font-awesome';
@import 'vendors/leaflet';
@import 'vendors/mapbox-gl';

@import 'vendors/include-media';
@import 'base/variables';
@import 'base/reset';
@import 'base/mixins';

@import 'layouts/common';
@import 'layouts/header';

@import 'pages/home';
@import 'pages/first';
@import 'pages/client_dashboard';
@import 'pages/provider_dashboard';
@import 'pages/c_add_tenders';
@import 'pages/charter';
@import 'pages/charter_details';
@import 'pages/p_charter';
@import 'pages/login';
@import 'pages/reset_pwd';
@import 'pages/signup';
@import 'pages/c_view_tenders';
@import 'pages/c_edit_tenders';
@import 'pages/p_view_tenders';
@import 'pages/p_charter_create';
@import 'pages/c_admin_company';
@import 'pages/message';
@import 'pages/notifications';
@import 'pages/directory';
@import 'pages/company';
@import 'pages/p_view_vessel';
@import 'pages/p_charter_edit';
@import 'pages/p_company_edit';
@import 'pages/c_helicopter';
@import 'pages/p_charter_add_helicopter';
@import 'pages/p_charter_helicopter';
@import 'pages/p_charter_details';
@import 'pages/p_charter_edit_helicopter';
@import 'pages/p_helicopter';
@import 'pages/dataTables';
/* Modals Custom Stylesheets */
@import "pages/modals";