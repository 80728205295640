#page-home {
	@include bg-cover;
	height: 100vh;
	position: relative;
	min-height: 750px;
	&:before {
		content: "";
		position:absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(50, 30, 100, 0.4);
	}
	.top {
		text-align: center;
		height: 45%;
		position: relative;
	    border-bottom: 1rem solid #FFF;
		h1 {
			position: absolute;
	    	bottom: 0;
		    left: 50%;
		    transform: translateX(-50%);
		    -webkit-transform: translateX(-50%);
		    -ms-transform: translateX(-50%);
		    font-size: 5vw;
		    color: #FFF;
		    font-weight: bold;
		    width: 100%;
		    text-transform: uppercase;
		    margin-bottom: 10px;
		}
		img {
			@include vertical-align-el;
			padding-bottom: 2rem;
		}
	}
	.bottom {
		position: relative;
		height: 55%;
		float: left;
		width: 100%;
		text-align: center;
		h5 {
			margin-top: 3rem;
			color: #FFF;
			font-weight: 400;
			@include media('<tablet') {
				font-size: 3rem;
			}
			@include media('<phone') {
				font-size: 2rem;
			}
		}
		.offshore-position {
			position: absolute;
			width: 100%;
			left: 50%;
			top: 50%;
			-webkit-transform: translate(-50%, -50%);
		    -ms-transform: translate(-50%, -50%);
		    transform: translate(-50%, -50%);
		    ul {
		    	display: inline-block;
		    	li {
		    		float: left;
		    		width: 6.33rem;
		    		@include media('<tablet') {
		    			width: 4.33rem;
		    		}
		    		i {
		    			font-size: 6.6rem;
		    			color: $map-marker-color;
		    		}
		    		img {
		    			width: 3.4rem;
		    		}
		    		&:nth-child(1) {
		    			padding-top: 5.2rem;
		    		}
		    		&:nth-child(2) {
		    			padding-top: 10rem;
		    		}
		    		&:nth-child(3) {
		    			padding-top: 5.2rem;
		    		}
		    		&:nth-child(5) {
		    			padding-top: 10rem;
		    		}
		    	}
		    	li+li {
		    		margin-left: 12rem;
		    		@include media('<desktop') {
		    			margin-left: 8rem;
		    		}
		    		@include media('<tablet') {
		    			margin-left: 6rem;
		    		}
		    		@include media('<phone') {
		    			margin-left: 4rem;
		    		}
		    	}
		    }
		}	
	}
	
}