#page-company-edit {
  .modal {
    .md-form {
      .prefix {
        margin-top: 12px;
      }
      .btn-primary {
        float: right;
      }
      p {
        margin-top: 30px;
        margin-bottom: 0px;
      }
      .checkbox-default {
        display: inline-block;
        margin-right: 20px;
        span {
          margin-left: 20px;
        }
      }
    }
    .close-icon {
      color: #666666;
    }
  }
  #edit-user {
    .modal-dialog {
      width: 100%;
      max-width: 800px;
    }
  }
}