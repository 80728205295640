#page-company {
  .headline {
    margin-top: 3rem;
    h3 a {
      color: #666;
      font-weight: 300;
      font-size: 36px;
    }
  }
  .right-col {
    h4 {
      margin-top: 2rem;
    }

  }
  padding-bottom: 4rem;
}

#page-company-edit {
  padding-bottom: 5rem;

  .err-msg {
    font-size: 1.4rem;
    text-align: right;
    margin-top: -5px;
  }

  .chips {
    margin-bottom: 1rem;
  }

  h4 {
    font-size: 1.8rem;
    font-weight: normal;
    margin-bottom: 2rem;
  }

  h4 i {
    color: #666;
  }

  .right-col {
    .card-block {
      padding-top: 0;
    }
  }

  #description {
    resize: none;
    box-sizing: content-box;
    height: auto;
  }
}

.table-container {
  padding: 1rem;
}
