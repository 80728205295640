#page-notifications {
	h2.mt-4 {
		margin-bottom: 2rem !important;
	}
	#mark_read_btn {
		margin-bottom: 3rem;
		font-weight: normal;
		font-size: 1.2rem;
	}

	.card {
		cursor: pointer;
		&.unread {
			background-color: #4285F4;
			color: #fff;
		}
	}

}
