#page-message, #page-client-view-tenders {
  .page-title-container {
    padding-bottom: 2rem;
    border-bottom: none;
  }
  .msg-container {
    box-shadow: 0 0 0.5rem $gray;
    height: calc(100vh - 200px);
    padding: 1rem;
    .msg-title {
      h4 {
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
      h5 {
        font-size: 1.7rem;
      }
      background-color: $super-light-gray;
      margin-top: 10px;
      padding-left: 5px;
    }
    .msg-date {
      background-color: $super-light-gray;
      padding-left: 5px;
      padding-bottom: 5px;
    }
    .msg-contacts {
      background-color: $super-light-gray;
      margin-top: 15px;
    }
    .msg-list {
      overflow: auto;
      height: calc(100% - 12rem);
    }
    .msg-people {
      overflow: auto;
      height: 100%;
      .msg-p-body {
        width: calc(100% - 50px);
      }
      a {
        color: $black;
        &:hover {
          color: $black;
        }
      }
      & > a.active {
        background-color: $super-light-gray;
      }
    }
    .msg-input {
      display: flex;
      border: 1px solid $gray;
      padding: 0.3rem;
      margin-left: 1rem;
      input[type=text] {
        padding-left: 0;
        padding-right: 0;
        margin-right: 3rem;
        height: 3rem;
      }
      a {
        margin: 0;
        font-size: 1.3rem;
      }
    }
    .waves-effect {
      overflow: inherit;
    }

    .message-container {
      margin-left: -9px;
      margin-right: -9px;
    }
    .name_margin {
      margin-top: 5px;
    }
    .user_add_icon {
      margin-right: 20px;
      color: $light-blue;
    }

    .phone_icon {
      margin-right: 10px;
      color: $light-blue;
    }

    .avatar-left-panel {
      padding: 5px 5px 0 5px;
    }
    .avatar-image {
      width: 50px;
      height: 50px;
    }
  }
}