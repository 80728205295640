* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

html, body {
	height: 100%;
	color: $text-color;
	background-color: #F8F8F8;
	font-size: 10px;
	font-weight: bold;
	@include media('>=tablet', '<desktop') {
		font-size: 9px;
	}
	@include media('<tablet') {
		font-size: 8px;
	} 
}
	
p {
	font-weight: 200;
	font-style: normal;

	margin: 0 0 1.35em 0;
	font-size: 1.7rem;
	line-height: 1.38;

	@include media('<tablet') {
		margin: 0 0 10px 0;
	} 	
}
p:last-child {
	margin: 0;
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
}
h1 {
	font-size: $font-size-h1;
	margin-bottom: 20px;
}
h2 {
	font-size: $font-size-h2;
	margin-bottom: 0.65em;
}
h3 {
	font-size: $font-size-h3;
	margin-bottom: 0.6em;
}
h4 {
	font-size: $font-size-h4;
	margin-bottom: 15px;
}
h5 {
	font-size: $font-size-h5;
	margin-bottom: 0.6em;
}
h6 {
	font-size: $font-size-h6;
	margin-bottom: 0.5em;
}
.mt-8 {
	margin-top: 8rem;
}
.text-justify {
	text-align: justify;
}
th, td {
	font-size: 1.7rem;
}
ul {
	list-style: none outside none;
	margin: 0;
	padding: 0;
	font-size: 1.7rem;
}
figure {
	margin: 0;
}
img {
	max-width: 100%;
	height: auto;
}
a img {
	border: 0 none;
}
textarea {
	resize: none;
}
i {
	display: inline-block;
	color: #fff;
	zoom: 1;
	font-style: normal;
	font-size: 1.6em;
	text-align: left;
	text-transform: uppercase;
}

section {
	position: relative;
	width: 100%;
	float: left;
}
.btn {
	padding: 1rem 1.4rem;
    font-size: 1.2rem;
}
.dropdown-item:focus, .dropdown-item:hover {
	background-color: #4285F4;
}
.navbar .dropdown-menu a {
	font-size: 1.5rem;
}
.form-inline {
	justify-content: center;
}
input[type=text], 
input[type=password], 
input[type=email], 
input[type=url], 
input[type=time], 
input[type=date], 
input[type=datetime-local], 
input[type=tel], 
input[type=number], 
input[type=search-md], 
input[type=search], 
textarea.md-textarea {
	font-size: 1.7rem;
	padding-left: 1rem;
	padding-right: 1rem;
}
.md-form label {
	font-size: 1.7rem;
}
.md-form label.active {
	font-size: 1rem;
}
.md-form select {
	margin-left: 3rem;
	width: 92%;
	width: calc(100% - 3rem);
	font-size: 1.7rem;
	font-weight: bold;
	color: #757575;
	height: 3rem;
	margin-top: 3rem;
}
label {
	font-size: 1.7rem;
	margin-bottom: 0;
}
input[type="checkbox"] + label {
	font-size: 1.5rem;
}
.classic-tabs {
	background-color: #DDD;
	li a {
		color: rgba(102, 102, 102, 0.7);
		&.active {
			color: $dark-gray;
			border-bottom-color: #1565c0;
		}
	}
}
.table a {
	margin-left: 0;
}
.leaflet-top {
	z-index: 400;
}
input[type=text].form-control, 
input[type=password].form-control, 
input[type=email].form-control, 
input[type=url].form-control, 
input[type=time].form-control, 
input[type=date].form-control, 
input[type=datetime-local].form-control, 
input[type=tel].form-control, 
input[type=number].form-control, 
input[type=search-md].form-control, 
input[type=search].form-control, 
textarea.md-textarea.form-control {
	padding-left: 8px;
	padding-right: 0;
	box-sizing: border-box;
	height: 3.3rem;
}
select.form-control:not([size]):not([multiple]) {
    height: calc(3.25rem + 2px);
}
.md-form .picker select {
	margin-left: 0;
	width: auto;
	margin-top: 0;
}
span {
	font-size: 1.7rem;
}
.breadcrumb {
	font-size: 1.3rem;
}
.breadcrumb-item + .breadcrumb-item::before {
	content: "\f105";
    font-family: FontAwesome;
}
textarea.md-textarea {
	padding-top: 0;
	padding-bottom: 0;
}