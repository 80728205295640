#page-signup {
	.signup {
		h3 {
			font-weight: 600;
		}
		.md-form {
			label {
				padding-left: 8px;
			}
		}
	}
}