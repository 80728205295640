#page-p-charter {
	.table-header {
		background-color: #eceeef;
		display: inline;
	}
	.table-container {
		padding: 1rem;
	}

	.vessels-container, .helicopters-container {
		.table-responsive {
			padding: 0 2rem 0 2rem;
		}
	}
}