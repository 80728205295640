.modal {
	.modal-dialog.modal-info {
		.modal-header {
			background-color: #4285F4 !important;
			color: #fff !important;

			.close > i, a {
				color: #fff !important;
			}
		}
	}

	.modal-header.no-bottom-border {
		border-bottom: none !important;
		.close i {
			color: #666666 !important;
		}
	}

	.flex-container {
		display: flex;
		flex-direction: column;

		.row {
			margin: 0 auto 10px;
		}
	}

	.modal-footer a.link {
		font-size: 10pt;
	}
}

#invite_tender.modal {
	.basic-textarea textarea {
		height: auto;
		font-size: 12pt;
		padding: 1rem;
	}
}