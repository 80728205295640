#page-directory {

  padding-bottom: 5rem;

  .headline {
    h1 {
      margin-top: 4rem;
      margin-bottom: 1rem;
    }
    margin-bottom: 4rem;
  }


  .btn-group {
    margin: 2rem 0 2rem 0;

    a {
      padding-left: 2rem;
      padding-right: 2rem;
      color: #fff;

      i {
        font-size: 3rem;
        padding-bottom: 0.5rem;
      }
    }
  }

  label.align-middle {
      height: 100%;
      margin-left: 3rem;
      input {
        font-size: 2.5rem;
      }
    }

  h2 {
    margin: 3rem 0 0 0;
  }
}