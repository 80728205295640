#page-charter-details {
  margin-bottom: 5rem;

  .charter-image {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .vessel-image {
    max-width: 100px;
  }
  .vessel-card {
    max-width: 800px;
    margin: auto;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }
  .vessel-card-body {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .table td, .table th {
    border-top: 0px;
  }

  .table td {
    width: 300px;
  }
  .spec-sheet-button {
    font-size: 1rem;
  }
  .vessel-details {
    margin-top: 2rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .vessel-name {
  }
  .vessel-info {
    margin-top: 2rem;
    padding-left: 3rem;
    padding-right: 3rem;

    .info-title {
      font-size: initial;
    }
    .info-description {
      margin-top: 10px;
    }
    .info-other {
      margin-bottom: 10px;
      margin-top: 20px;

      p {
        margin: 0 0 0 0;
      }
    }
  }
  .second-card {
    margin-top: 4em;

    .table-header {
      .title {
        font-weight: 700;
        padding-top: 15px;
        padding-left: 15px;
      }
      .filter {
        button {
          margin-top: 10px;
        }
      }
    }
  }

  .availability{
    p{
      margin: 0;
    }
  }
}

