#page-charter {
	.page-title-container {
		padding-bottom: 2rem;
		border-bottom: none;
	}
	table.table td a {
		display: inline-block;
	}
	#charter_map {
		height: 400px;
		margin-bottom: 3rem;
		z-index: 1;
	}
	#charter-table {
		background-color: white !important;
		border-radius: 0;
	}

	.filter {
		text-align: right;
		margin-top: 2rem;
		.title {
			padding-right: 2rem;
		}
		.filter-comp {
			display: inline-block;
			border-radius: 3px;
			margin-right: 1rem;
			@include media('<480px') {
				width: 35%;
				margin-bottom: 1rem;
			}
		}
		input[type=text] {
			text-align: center;
			padding-left: 0;
			padding-right: 0;
			border-bottom-color: #FFF;
			&:focus {
				border-bottom: 1px solid #4285F4;
			}
		}
		.filter-form {
			display: flex;
			justify-content: flex-end;
			margin-bottom: 1.5rem;
			//background: #CCC;
			padding: 1rem;
			@include media('<480px') {
				flex-wrap: wrap;
			}
			.filter-submit {
				font-size: 1.5rem;
				padding: 0.2rem 1rem;
				border: none;
				border-radius: 3px;
				cursor: pointer;
			}
		}
	}
	.charter-detail {
		width: 200px;
		.photo {
			width: 100%;
		}
		.spec {
			background: rgb(38, 69, 139);
			color: #FFF;
			margin-top: 1rem;
			padding: 1rem;
			div span {
				&:first-child {
					padding-right: 1rem;
					font-weight: bold;
				}
			}
		}
	}

	.table-header {
		background-color: #eceeef;
		display: inline;
	}
	.table-container {
		margin-top: 2rem;
		padding: 0;
	}

	.vessels-table {
		padding: 0 2rem 0 2rem;
	}
}