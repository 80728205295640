#page-provider-dashboard {
	.page-title-container {
		padding-bottom: 4rem;
	}
	.btn {
	    font-size: 1.7rem;
	}
	.dropdown-menu {
	    font-size: 1.5rem;
	}
	@include media('>480px') {
		.table-responsive {
			overflow-x: visible;
		}
	}
}